export type Events = Record<string, (...args: any[]) => unknown>

export type OffFn<E extends Events, T extends keyof E> = ChainFn<E, () => E[T]>

type ChainFn<E extends Events, F extends AnyFunction> = F & {
  on<T extends keyof E>(eventName: EventName<T>, fn: E[T], options?: { once?: boolean }): OffFn<E, T>
}


type EventName<T> = (T & string) | Nullish | false

export default class EventEmitter<E extends Events = Events> {
  #listeners: { [K in keyof E]?: E[K][] } = {}

  on<T extends keyof E>(eventName: EventName<T>, fn: E[T], options: { once?: boolean } = {}) {
    if (!eventName) {
      return this.#chainFn((() => {}) as OffFn<E, T>)
    }

    const fn_ = options.once ?
      this.#onceFn(eventName, fn) :
      fn
      
    this.#fns(eventName).push(fn_)
    return this.#offFn(eventName, fn_)
  }

  once<T extends keyof E>(eventName: EventName<T>, fn: E[T]) {
    return this.on(eventName, fn, { once: true })
  }

  off<T extends keyof E>(eventName: EventName<T>, fn: E[T]) {
    if (!eventName) return noop as E[T]
    this.#fns(eventName).pull(fn)
    return fn
  }

  bind(handlers: { [K in keyof E]: E[K] }) {
    Object.forEach(handlers, ([key, value]) => {
      this.on(key, value)
    })

    return this
  }

  unbindAll() {
    this.#listeners = {}
  }

  has<T extends keyof E>(eventName: EventName<T>) {
    return Boolean(this.#listeners[eventName]?.length)
  }

  emit<T extends keyof E, A extends Parameters<E[T]>>(eventName: T & string, ...args: A): void {
    [...this.#fns(eventName)].forEach(fn => {
      fn(...args)
    })
  }

  async emitSerial<T extends keyof E, A extends Parameters<E[T]>>(eventName: T & string, ...args: A) {
    for (const fn of this.#fns(eventName)) {
      await fn(...args)
    }
  }

  emitFn<T extends keyof E>(eventName: EventName<T>) {
    if (!eventName) return noop
    return (...args: Parameters<E[T]>) => this.emit(eventName, ...args)
  }


  listeners() {
    return this.#listeners
  }

  #fns<T extends keyof E>(eventName: T & string): E[T][] {
    return this.#listeners[eventName] ??= []
  }

  #onceFn<T extends keyof E>(eventName: T & string, fn: E[T]) {
    const onceFn = ((...args: any[]) => {
      this.off(eventName, onceFn)
      fn(...args)
    }) as E[T]

    return onceFn
  }

  #offFn<T extends keyof E>(eventName: T & string, fn: E[T]): OffFn<E, T> {
    return this.#chainFn(() => this.off(eventName, fn))
  }

  #chainFn<F extends AnyFunction>(fn: F) {
    const chained = fn as ChainFn<E, F>
    chained.on = this.on.bind(this)
    return chained
  }
}